<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form ref="guide" @submit.prevent.native="saveFn">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Дата задачи">
            <el-date-picker
              v-model="date"
              style="width: 100%"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="{ firstDayOfWeek: 1 }"
              type="date"
              placeholder="Выберите дату задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Время задачи">
            <el-time-select
              v-model="time"
              style="width: 100%"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="Выберите время задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Описание задачи">
            <el-input
              v-model="description"
              type="textarea"
              :rows="4"
              placeholder="Напишите описание задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Состояние задачи">
            <el-switch v-model="state" active-text="Выполнена" inactive-text="Не выполнена" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="types" label="Тип задачи">
            <el-select v-model="type" style="width: 100%" placeholder="Выберите тип задачи">
              <el-option
                v-for="item in types.data"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="state" :span="24">
          <el-form-item label="Описание выполнения задачи">
            <el-input
              v-model="desc_state"
              type="textarea"
              :rows="4"
              placeholder="Напишите как выполнили эту задачу"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="managers && !isAvailable" :span="12">
          <el-form-item label="Менеджер">
            <el-select v-model="manager" style="width: 90%" placeholder="Выберите менеджера">
              <el-option
                v-for="item in managers.data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="success" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { FETCH_TYPES } from '@/app/manager/shared/state/types/action_types';
import { FETCH_EMPLOYEES } from '@/app/settings/employes/shared/state/types/action_types';

export default {
  name: 'CreateTask',
  props: {
    title: {
      type: String,
      default: 'Добавить блюдо',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      date: null,
      time: null,
      description: null,
      state: null,
      desc_state: null,
      type: null,
      manager: null,
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapState({
      managers: (state) => state.employesState.employesData.data,
      types: (state) => state.managerState.managerData.types,
      managerLoading: (state) => state.employesState.employesData.loading,
      isAvailable: (state) => ['manager'].includes(state.authState.authState.role),
      user: (state) => state.authState.authState.me,
    }),
  },
  async created() {
    if (!this.types) {
      await this.fetchTypes();
    }
    if (!this.managers) {
      await this.fetchManagers();
    }
  },
  methods: {
    ...mapActions({
      fetchManagers: FETCH_EMPLOYEES,
      fetchTypes: FETCH_TYPES,
    }),
    closeModal() {
      this.visible = false;
      this.$emit('close-modal');
    },
    async saveFn() {
      await this.$store.dispatch('STORE_TASK', {
        date: this.date,
        time: this.time,
        description: this.description,
        state: this.state,
        desc_state: this.desc_state,
        type_id: this.type,
        user_id: this.isAvailable ? this.user.id : this.manager,
        client_id: this.$route.params.id,
        options: {
          clientId: this.$route.params.id,
          userId: this.user.id,
        },
      });
      this.$notify({
        title: 'Успешно',
        message: 'Задача успешно добавлена',
        duration: 1500,
        type: 'success',
      });
      this.closeModal();
    },
  },
};
</script>
