<template>
  <div>
    <div class="is-flex is-justify-content-flex-end">
      <el-button type="primary" icon="el-icon-plus" @click="createTask">
        Добавить задачу
      </el-button>
    </div>
    <app-table
      :loading="taskLoading"
      :data="tasks"
      :fields="fields"
      :rules="rules"
      @reload="changePage"
      @edit="editTask"
      @delete="deleteTask"
    />
    <component
      :is="actionComponent"
      :show.sync="modal.show"
      :title="modal.title"
      @close-modal="modal.action = null"
    />
  </div>
</template>

<script>
import { FETCH_TASKS, SELECT_TASK } from '@/app/manager/shared/state/types/action_types';
import { mapState } from 'vuex';
import AppTable from '@/app/shared/components/app-table/app-table.vue';
import * as actionsTypes from '@/app/auth/shared/state/types/action_types';
import CreateTask from './create-task.vue';
import UpdateTask from './update-task.vue';

export default {
  name: 'ClientTasks',
  components: { AppTable },
  data() {
    return {
      fields: [
        { label: '#', value: 'id', width: 50 },
        { label: 'Описание', value: 'description' },
        { label: 'Дата', value: 'date' },
        { label: 'Тип', value: 'type.title' },
        { label: 'Состояние', value: 'state' },
        { label: 'Автор', value: 'user.name' },
      ],
      modal: {
        action: null,
        title: null,
        show: false,
      },
    };
  },
  computed: {
    ...mapState({
      tasks: (state) => state.managerState.managerData.data ?? [],
      taskLoading: (state) => state.managerState.managerData.loading,
      isAvailable: (state) => ['manager'].includes(state.authState.authState.role),
      me: (state) => state.authState.authState.me,
    }),
    actionComponent() {
      if (!this.modal.action) {
        return null;
      }
      return this.modal.action === 'create' ? CreateTask : UpdateTask;
    },
    rules() {
      if (this.isAvailable) {
        return [
          {
            value: 'edit',
            type: 'primary',
            icon: 'el-icon-edit',
          },
        ];
      }
      return [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ];
    },
  },
  async created() {
    if (!this.me) {
      await this.$store.dispatch(actionsTypes.ME);
    }
    if (this.isAvailable) {
      await this.$store.dispatch(FETCH_TASKS, {
        clientId: this.$route.params.id,
        userId: this.me.id,
      });
      return;
    }
    await this.$store.dispatch(FETCH_TASKS, {
      clientId: this.$route.params.id,
    });
  },
  methods: {
    createTask() {
      this.modal = {
        action: 'create',
        title: 'Добавить задачу',
        show: true,
      };
    },
    editTask(task) {
      this.modal = {
        action: 'edit',
        title: 'Редактировать задачу',
        show: true,
      };
      this.$store.dispatch(SELECT_TASK, task);
    },
    async changePage(page) {
      await this.$store.dispatch(FETCH_TASKS, { clientId: this.$route.params.id, page });
    },
    deleteTask(task) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$store.dispatch('DELETE_TASK', {
              id: task.id,
              options: { clientId: this.$route.params.id },
            });
            this.$notify({
              title: 'Успешно',
              message: 'Задача успешно удалена',
              duration: 1500,
              type: 'success',
            });
          } catch (error) {
            this.$notify.error({
              title: 'Ошибка',
              message: 'Ошибка',
              duration: 1500,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
