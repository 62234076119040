<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form ref="guide" @submit.prevent.native="saveFn">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Дата задачи">
            <el-date-picker
              v-model="task.date"
              style="width: 100%"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="{ firstDayOfWeek: 1 }"
              type="date"
              placeholder="Выберите дату задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Время задачи">
            <el-time-select
              v-model="task.time"
              style="width: 100%"
              :picker-options="{
                start: '00:00',
                step: '00:05',
                end: '23:55',
              }"
              placeholder="Выберите время задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Описание задачи">
            <el-input
              v-model="task.description"
              type="textarea"
              :rows="4"
              placeholder="Напишите описание задачи"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Состояние задачи">
            <el-switch v-model="task.state" active-text="Выполнена" inactive-text="Не выполнена" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="types" label="Тип задачи">
            <el-select v-model="task.type.id" style="width: 100%" placeholder="Выберите тип задачи">
              <el-option
                v-for="item in types.data"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="task.state" :span="24">
          <el-form-item label="Описание выполнения задачи">
            <el-input
              v-model="task.desc_state"
              type="textarea"
              :rows="4"
              placeholder="Напишите как выполнили эту задачу"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="managers && !isManager" :span="12">
          <el-form-item label="Менеджер">
            <el-select
              v-model="task.manager.id"
              style="width: 90%"
              placeholder="Выберите менеджера"
            >
              <el-option
                v-for="item in managers.data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="success" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { FETCH_TYPES } from '@/app/manager/shared/state/types/action_types';
import { FETCH_EMPLOYEES } from '@/app/settings/employes/shared/state/types/action_types';

export default {
  name: 'UpdateTask',
  props: {
    title: {
      type: String,
      default: 'Добавить блюдо',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      dish: { title: null },
      price: null,
      branch: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapState({
      task: (state) => state.managerState.managerData.task,
      managers: (state) => state.employesState.employesData.data,
      types: (state) => state.managerState.managerData.types,
      isManager: (state) => ['manager'].includes(state.authState.authState.role),
      managerLoading: (state) => state.employesState.employesData.loading,
    }),
  },
  async created() {
    if (!this.types) {
      await this.fetchTypes();
    }
    if (!this.managers) {
      await this.fetchManagers();
    }
  },
  methods: {
    ...mapActions({
      fetchManagers: FETCH_EMPLOYEES,
      fetchTypes: FETCH_TYPES,
    }),
    closeModal() {
      this.visible = false;
      this.$emit('close-modal');
    },
    async saveFn() {
      await this.$store.dispatch('UPDATE_TASK', {
        id: this.task.id,
        date: this.task.date,
        time: this.task.time,
        description: this.task.description,
        state: this.task.state,
        desc_state: this.task.desc_state,
        type_id: this.task.type.id,
        user_id: this.task.manager.id,
        client_id: this.$route.params.id,
        options: {
          clientId: this.$route.params.id,
          userId: this.task.manager.id,
        },
      });
      this.$notify({
        title: 'Успешно',
        message: 'Задача успешно обновлена',
        duration: 1500,
        type: 'success',
      });
      this.closeModal();
    },
  },
};
</script>
